<template>
  <v-card width="60%" style="margin: 15px auto;">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Устройства</v-toolbar-title>
    </v-toolbar>
    <CreateDialog
      v-if="createDialogShow"
      @input="getDevices()"
      @close="createDialogShow = false"
    />
    <UpdateDialog
      v-if="updateDialogShow"
      :id="updateDialogId"
      @input="getDevices()"
      @close="updateDialogShow = false"
    />
    <v-card-text>
      <v-row align="baseline">
        <v-col cols="8">
          <v-text-field
            label="Поиск..."
            v-model="filter"
            append-icon="mdi-magnify"
            hide-details
          />
        </v-col>
        <v-col cols="4" class="text-center">
          <v-btn color="info" @click="createDialogShow = true">Добавить новое</v-btn>
        </v-col>
      </v-row>
      <v-simple-table>
        <thead>
          <tr>
            <!-- <th class="text-left">Тип</th> -->
            <th class="text-left">Название</th>
            <th class="text-left">База</th>
            <th class="text-left">Цех</th>
            <!-- <th class="text-left">Бригада</th> -->
            <!-- <th class="text-left">Статус</th> -->
            <th v-if="isAdmin" style="width: 50px;"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="d in devices">
            <td>{{d.name}}</td>
            <td>{{d.base || 'не указана'}}</td>
            <td>{{d.workshop || 'не указан'}}</td>
            <td v-if="isAdmin">
              <div style="display: flex; align-items: center;">
                <v-btn icon @click="updateDevice(d.id)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="deleteDevice(d.id)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
          <tr v-if="devices.length == 0">
            <td class="text-center" colspan="4">Ничиго не найдено</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import CreateDialog from './components/CreateDialog.vue'
import UpdateDialog from './components/UpdateDialog.vue'

export default {
  components: { CreateDialog, UpdateDialog },
  data: () => ({
    createDialogShow: false,
    updateDialogShow: false,
    updateDialogId: 0,
    devices: [],
    filter: '',
    searchRequestTimeout: null,
  }),
  computed: {
    isAdmin() {
      return this.$store.getters['user/isAdmin']
    }
  },
  methods: {
    deleteDevice(id) {
      this.$axios.post('organization/device/delete', { id: id }).then(() => this.getDevices())
    },
    updateDevice(id) {
      this.updateDialogId = id
      this.updateDialogShow = true
    },
    getDevices() {
      this.$axios.get('organization/device/index', { params: { filter: this.filter } })
      .then(r => {
        this.devices = r.data.items.slice()
      })
    }
  },
  watch: {
    filter(value) {
      if(value && value.length >= 2) {
        clearTimeout(this.searchRequestTimeout);
        this.searchRequestTimeout = setTimeout(() => this.getDevices(), 1000);
      }
    }
  },
  mounted() {
    this.getDevices()
  }
}
</script>
