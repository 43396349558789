<template>
  <v-dialog
    value="true"
    persistent
    max-width="55%"
    >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title primary-title>Добавление устройства</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="UUID"
                v-model="device.uuid"
                no-details
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Название"
                v-model="device.name"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="База"
                v-model="device.base"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Цех"
                v-model="device.workshop"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" @click="$emit('close')">Отмена</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="create">Далее</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => ({
    device: {
      uuid: '',
      name: '',
      base: '',
      workshop: '',
    },
  }),
  methods: {
    create() {
      this.$axios.post('organization/device/create', this.device).then(r => {
        this.$store.dispatch('setAlert', { message: r.data.message, type: 'info'})
        this.$emit('input', this.device)
        this.$emit('close')
      })
    },
  },
}
</script>
